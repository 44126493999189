.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: min(
    var(--ag-line-height, calc(var(--ag-row-height) - 10px)),
    var(--ag-row-height) - 10px
  ) !important;
}

.ag-header-cell-text {
  font-size: 11px !important;
}
.ag-header-cell-label .ag-header-cell-text {
  white-space: break-spaces !important;
}
