#tabList::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#tabList::-webkit-scrollbar {
  height: 5px; /* height of horizontal scrollbar ← You're missing this */
  width: 5px;
  background-color: #f5f5f5;
}

#tabList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c7c7c7;
}
#tabList {
  overflow-y: hidden;
  overflow-x: scroll;
  height: 100%;
}
